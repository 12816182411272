var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Section',{attrs:{"title":_vm.$t('valuations')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('MenuActions',{attrs:{"actions":_vm.actions}})],1)]},proxy:true}])},[_c('Grid',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(item.createdDate))+" ")]}},{key:"item.actualMarketValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "active" ? _vm.$n(item.actualMarketValue, "currency") : "-")+" ")]}},{key:"item.unrestrictedMarketValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "active" ? _vm.$n(item.unrestrictedMarketValue, "currency") : "-")+" ")]}},{key:"item.activatedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "active" ? _vm.$d(item.activatedDate) : "-")+" ")]}},{key:"item.expDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "active" ? _vm.$d(item.expDate) : "-")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeText(item.type))+" ")]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
          path: '/documents',
          query: { filter: ("valuation," + (item.id)) },
        }}},[_c('v-icon',{attrs:{"title":_vm.$t('view')}},[_vm._v(" mdi-eye ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }