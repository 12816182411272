























































































































import { appStore } from "@/app.store";
import { companiesStore } from "@/service/companies/companies.store";
import Vue from "vue";
import Widget from "@/components/Widget.vue";
import { CompanyAnalysisDto } from "@/service/companies/companies.service.dto";
import { getCompanyAnalysis } from "@/service/companies/companies.service";
import SectionCompanyDetails from "./SectionCompanyDetails.vue"

export default Vue.extend({
  components: {
    Widget,
    SectionCompanyDetails
  },
  data() {
    return {
      companyAnalysis: undefined as CompanyAnalysisDto | undefined,
      loading: true,
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        this.companyAnalysis = await getCompanyAnalysis(companiesStore.state.currentId);
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    }
  },
});
