






























































import Vue from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import { createValuationForCompany } from "@/service/valuations/valuations.service";
import { ValuationType } from "@/service/valuations/valuations.service.dto";

export default Vue.extend({
  data() {
    return {
      valuationsTypeOptions: [
        { value: "options", text: this.$t("options").toString() },
        { value: "shares", text: this.$t("shares").toString() },
      ],
      valuation: {
        type: "" as ValuationType,
      },
      files: [] as File[],
      loading: false,
    };
  },
  methods: {
    async create() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;

        await createValuationForCompany(
          companiesStore.state.currentId,
          this.valuation,
          this.files
        );

        appStore.snackbar(this.$t("created").toString(), "success");
        this.$router.replace({ path: "/company/valuations" });
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      } finally {
        this.loading = false;
      }
    },
  },
});
