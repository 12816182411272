





































































































































































































































































import { appStore } from "@/app.store";
import { getCompany, update } from "@/service/companies/companies.service";
import { CompanyDto } from "@/service/companies/companies.service.dto";
import { companiesStore } from "@/service/companies/companies.store";
import DateField from "@/components/DateField.vue";
import Vue from "vue";

function startOfToday(): Date {
  return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
}

export default Vue.extend({
  components: {
    DateField
  },
  data() {
    return {
      company: {
        id: "",
        name: "",
        taxId: "",
        taxPercent: 0,
        sharePrice: 0,
        shares: 0,
        classes: [],
        valuation: {
          actualMarketValue: 0,
          unrestrictedMarketValue: 0,
          expDate: new Date()
        },
        address: {
          id: "",
          country: "",
          state: "",
          city: "",
          streetAddress: "",
          postCode: ""
        },
        incorporationDate: new Date(),
        taxOffice: "",
        companyType: "",
        accountingStartDate: new Date(),
        accountingEndDate: new Date(),
        isFree: true,
        crnNumber: "",
        distinctiveName: "",
      } as CompanyDto,
      loading: true,
      incorporationDate: startOfToday().toISOString().substr(0, 10),
      accountingStartDate: startOfToday().toISOString().substr(0, 10),
      accountingEndDate: startOfToday().toISOString().substr(0, 10),
      companyTypes: [{ name: this.$t('greekCompanyTypes.ae') }, { name: this.$t('greekCompanyTypes.ike') }],
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        this.company = await getCompany(companiesStore.state.currentId);
        console.log(this.company.accountingEndDate.toISOString().substr(0,10));
        if(this.company.incorporationDate !== null && this.company.incorporationDate.toISOString().substr(0,10) != "0001-01-01"){
          this.incorporationDate = this.company.incorporationDate.toISOString().substr(0,10);
        }
        else {
          this.incorporationDate = startOfToday().toISOString().substr(0, 10);
        }
        
        if(this.company.accountingStartDate !== null && this.company.accountingStartDate.toISOString().substr(0,10) != "0001-01-01"){
          this.accountingStartDate = this.company.accountingStartDate.toISOString().substr(0,10);
        }
        else{
          this.accountingStartDate = startOfToday().toISOString().substr(0, 10);
        }
        
        if(this.company.accountingEndDate !== null && this.company.accountingEndDate.toISOString().substr(0,10) != "0001-01-01"){
          this.accountingEndDate = this.company.accountingEndDate.toISOString().substr(0,10);
        }
        else{
          this.accountingEndDate = startOfToday().toISOString().substr(0, 10);
        }
        
        //console.log(this.company);
        //this.incorporationDate = this.company.incorporationDate.toISOString().substr(0,10);
        //this.accountingStartDate = this.company.accountingStartDate.toISOString().substr(0,10);
        //this.accountingEndDate = this.company.accountingEndDate.toISOString().substr(0,10);

        //console.log(this.accountingStartDate);
        //console.log(this.accountingEndDate);
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async commit() {
      try {
        this.loading = true;
        this.company.accountingStartDate = new Date(this.accountingStartDate);
        this.company.accountingEndDate = new Date(this.accountingEndDate);
        this.company.incorporationDate = new Date(this.incorporationDate);
        await update(companiesStore.state.currentId, this.company);
        this.loading = false;
        appStore.snackbar(this.$t("shared.ok").toString(), "success");
        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
  },
});
