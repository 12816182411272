var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.$t("companySettingsModule.navHeaderNormalized")))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('name'),"error-messages":errors},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('distinctiveName'),"error-messages":errors},model:{value:(_vm.company.distinctiveName),callback:function ($$v) {_vm.$set(_vm.company, "distinctiveName", $$v)},expression:"company.distinctiveName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('taxId'),"error-messages":errors},model:{value:(_vm.company.taxId),callback:function ($$v) {_vm.$set(_vm.company, "taxId", $$v)},expression:"company.taxId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('taxOffice'),"error-messages":errors},model:{value:(_vm.company.taxOffice),callback:function ($$v) {_vm.$set(_vm.company, "taxOffice", $$v)},expression:"company.taxOffice"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('crnNumber'),"error-messages":errors},model:{value:(_vm.company.crnNumber),callback:function ($$v) {_vm.$set(_vm.company, "crnNumber", $$v)},expression:"company.crnNumber"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateField',{attrs:{"label":_vm.$t('incorporationDate'),"error-messages":errors},model:{value:(_vm.incorporationDate),callback:function ($$v) {_vm.incorporationDate=$$v},expression:"incorporationDate"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"companyType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.companyTypes,"item-text":"name","item-value":"name","label":_vm.$t('companyType'),"error-messages":errors},model:{value:(_vm.company.companyType),callback:function ($$v) {_vm.$set(_vm.company, "companyType", $$v)},expression:"company.companyType"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateField',{attrs:{"label":_vm.$t('accountingStartDate'),"error-messages":errors},model:{value:(_vm.accountingStartDate),callback:function ($$v) {_vm.accountingStartDate=$$v},expression:"accountingStartDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateField',{attrs:{"label":_vm.$t('accountingEndDate'),"error-messages":errors},model:{value:(_vm.accountingEndDate),callback:function ($$v) {_vm.accountingEndDate=$$v},expression:"accountingEndDate"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"h6 mt-6"},[_vm._v(_vm._s(_vm.$t("address")))]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('country'),"error-messages":errors},model:{value:(_vm.company.address.country),callback:function ($$v) {_vm.$set(_vm.company.address, "country", $$v)},expression:"company.address.country"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('addressState'),"error-messages":errors},model:{value:(_vm.company.address.state),callback:function ($$v) {_vm.$set(_vm.company.address, "state", $$v)},expression:"company.address.state"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('city'),"error-messages":errors},model:{value:(_vm.company.address.city),callback:function ($$v) {_vm.$set(_vm.company.address, "city", $$v)},expression:"company.address.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('streetAddress'),"error-messages":errors},model:{value:(_vm.company.address.streetAddress),callback:function ($$v) {_vm.$set(_vm.company.address, "streetAddress", $$v)},expression:"company.address.streetAddress"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('postCode'),"error-messages":errors},model:{value:(_vm.company.address.postCode),callback:function ($$v) {_vm.$set(_vm.company.address, "postCode", $$v)},expression:"company.address.postCode"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-10"},[_c('v-col',[_c('v-btn',{staticClass:"min-width-2 mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('v-btn',{staticClass:"min-width-2",attrs:{"color":"secondary","depressed":"","disabled":invalid,"loading":_vm.loading},on:{"click":_vm.commit}},[_vm._v(" "+_vm._s(_vm.$t("update"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }