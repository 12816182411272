



























































































import { CompanyAnalysisDto } from "@/service/companies/companies.service.dto";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    companyAnalysis: {
      type: Object as PropType<CompanyAnalysisDto>,
      required: true,
    },
  },
});
