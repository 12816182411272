





























































import Vue from "vue";
import { appStore } from "@/app.store";
import {
  ValuationDto,
  ValuationStatus,
  ValuationType,
} from "@/service/valuations/valuations.service.dto";
import { companiesStore } from "@/service/companies/companies.store";
import { getValuationsOfCompany } from "@/service/valuations/valuations.service";
import { MenuAction, MenuActions } from "@/components/menu-actions";
import Section from "@/components/Section.vue";
import Grid from "@/components/grid/Grid.vue";

const statusTextMap: Record<ValuationStatus, string> = {
  pending: "pending",
  active: "active",
};

const typeTextMap: Record<ValuationType, string> = {
  options: "options",
  shares: "shares",
};

export default Vue.extend({
  components: {
    MenuActions,
    Section,
    Grid
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("created").toString(),
          align: "start",
          sortable: false,
          value: "createdDate",
        },
        {
          text: this.$t("type").toString(),
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: this.$t("status").toString(),
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: this.$t("activatedDate").toString(),
          align: "start",
          sortable: false,
          value: "activatedDate",
        },
        {
          text: this.$t("expDate").toString(),
          align: "start",
          sortable: false,
          value: "expDate",
        },
        {
          text: this.$t("amv").toString(),
          align: "start",
          sortable: false,
          value: "actualMarketValue",
        },
        {
          text: this.$t("umv").toString(),
          align: "start",
          sortable: false,
          value: "unrestrictedMarketValue",
        },
        {
          text: this.$t("documents").toString(),
          align: "center",
          sortable: false,
          value: "documents",
        },
      ],
      items: [] as Array<ValuationDto>,
      actions: [] as Array<MenuAction>,
    };
  },
  async mounted() {
    this.populateActions();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        this.items = await getValuationsOfCompany(
          companiesStore.state.currentId
        );
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    populateActions() {
      if (this.$can("create", "Valuation"))
        this.actions.push({
          text: this.$t("optionsModule.addValuation").toString(),
          clickHandler: this.createValuation,
        });
    },
    createValuation() {
      this.$router.push({ path: "/company/valuations/create" });
    },
    getStatusText(status: ValuationStatus): string {
      return this.$t(statusTextMap[status]).toString();
    },
    getTypeText(type: ValuationType): string {
      return this.$t(typeTextMap[type]).toString();
    },
  },
});
